/*
#######################
Media Cases Definition
Scaled Down: 905px;
Mobile: 500px;
#######################
*/
html,
body {
  overflow-x: hidden;
}

/*######### Header #########*/
.header {
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 60px;
  line-height: 60px;
}

.header > .icon {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.header > .icon > .img {
  width: 35px;
  height: 35px;
}

.header > .navbar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.header > .navbar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.header > .navbar > a {
  font-size: 14px;
  color: #1c2f3f;
  padding: 15px;
  text-decoration: none;
  transition: 0.5s;
}

.header > .navbar a:hover {
  color: #1989ff;
}

.header > .navbar .active {
  /*color: var(--blue);  //Original Actived Color*/
  color: #1989ff;
  font-weight: bold;
}

.header > .navbar > .about-us {
  font-size: 14px;
  color: #1c2f3f;
  padding: 15px;
  text-decoration: none;
  transition: 0.5s;
  cursor: default;
  position: relative;
}

.header > .navbar > .about-us:hover {
  color: #1989ff;
}

.header > .navbar > .about-us.active {
  color: #1989ff;
}

.header > .navbar > .about-us > .title {
  line-height: 14px;
}

.header > .navbar > .about-us > .submenu {
  position: absolute;
  top: 40px;
  transition: 0.5s;
  height: 0;
  overflow-y: hidden;
  display: block;
}

.header > .navbar > .about-us:hover > .submenu {
  height: 80px;
}

.header > .navbar > .about-us a {
  display: block;
  font-size: 14px;
  color: #1c2f3f;
  padding: 5px 0;
  text-decoration: none;
  transition: 0.5s;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.header > .navbar > .about-us a.active {
  color: #1989ff;
}

.header > .navbar > .about-us a:hover {
  color: #1989ff;
}

.header > .navbar > .dropdown {
  font-size: 14px;
  line-height: 20px;
}

.header > .navbar > .dropdown svg {
  height: 14px;
  width: 14px;
}

.header > .navbar > .dropdown > button {
  font-size: 14px;
  box-shadow: none;
}

.header > .navbar > .dropdown a {
  font-size: 14px;
}

.header > .navbar > .dropdown a:active {
  background-color: #1989ff;
}

.header > .navbar-m-button {
  display: none;
  cursor: pointer;
}

.header > .navbar > .gotoAppButton {
}

.header > .navbar > .gotoAppButton > a {
  background-color: #1989ff;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: white;
  text-decoration: none;
  transition: 0.2s;
}

.header > .navbar > .gotoAppButton > a:hover {
  background-color: #82d4f53a;
  color: #1989ff;
  transition: 0.2s;
}

/*mobile nav-bar*/
@media (max-width: 1255px) {
  .header > .navbar-m-button {
    display: inline-block;

    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 20px;
    width: 35px;
    height: 35px;
  }
  .header > .navbar {
    display: none;
  }
}

.header > .navbar-m-button > * {
  width: 20px;
  height: 20px;
}

@keyframes folding {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-420px);
  }
}

@keyframes expanding {
  from {
    transform: translateY(-420px);
  }

  to {
    transform: translateY(0);
  }
}

.header > .navbar-m {
  overflow: hidden;
  position: absolute;
  top: 80px;
  width: 100%;
  height: 2000px;
  pointer-events: none;
}

.header > .navbar-m.expand {
  pointer-events: all;
}

.header > .navbar-m > .navbar-list {
  padding-left: 20px;
  background-color: #1989ff;
  animation: folding 0.3s forwards;
}

.header > .navbar-m.expand > .navbar-list {
  animation: expanding 0.3s forwards;
  padding-bottom: 20px;
}

.header > .navbar-m > .navbar-list > a {
  display: block;
  color: #1c2f3f;
  text-decoration: none;
}

.header > .navbar-m > .navbar-list > .dropdown {
  display: block;
  color: #1c2f3f;
  line-height: 40px;
}

.header > .navbar-m > .navbar-list > .dropdown svg {
  height: 14px;
  width: 14px;
}

.header > .navbar-m > .navbar-list > .dropdown > button {
  padding-left: 0;
}

.header > .navbar-m > .navbar-list > .dropdown a {
  font-size: 14px;
}

.header > .navbar-m > .navbar-list > .dropdown a:active {
  background-color: #1989ff;
}

.header > .navbar-m > .touchFold {
  top: 80px;
  width: 100%;
  height: 100%;
}

.header > .navbar-m > .navbar-list > .gotoAppButton > a {
  background-color: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #1989ff;
  text-decoration: none;
  transition: 0.2s;
}

.header > .navbar-m > .navbar-list > .gotoAppButton > a:hover {
  background-color: #ffffff3a;
  color: white;
  transition: 0.2s;
}

/*######### Body #########*/
/*Common Conponents*/
.banner {
  position: relative;
  background: #1c2f3f;
  border-bottom-right-radius: 100px;
  width: 62vw;
  padding: 90px 20px 80px 80px;
}

.banner > .decorate-ball {
  position: absolute;
  bottom: 50px;
  right: -20px;
  background: #1989ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.banner > .paragraph {
  max-width: 500px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #ffae65;
  font-size: 15px;
  line-height: 20px;
}

.banner > .title {
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  color: white;
  font-size: 50px;
  line-height: 55px;
}

@media (max-width: 500px) {
  .banner {
    position: relative;
    background: #1c2f3f;
    border-bottom-right-radius: 100px;
    width: 80vw;
    padding: 90px 20px 80px 40px;
  }
}

.trait {
  position: relative;
  margin-top: 80px;
}

.trait > .color-block {
  position: relative;
  background: #1c2f3f;
  border-bottom-right-radius: 100px;
  width: 310px;
  height: 600px;
}

.trait > .color-block > .image {
  position: absolute;
  top: 50%;
  left: 5vw;
  transform: translateY(-50%);
  width: 40vw;
}

.trait .imageFixed {
  display: none;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translateX(-50%) translateY(-50%);
  width: 80vw;
  z-index: 99;
}

.trait-reverse .imageFixed {
  display: none;
  position: absolute;
  left: 50%;
  top: 60%;

  transform: translateX(-50%) translateY(-50%);
  width: 80vw;
  z-index: 99;
}

.trait > .text-block {
  position: absolute;
  top: 50%;
  right: 5vw;
  transform: translateY(-50%);
  margin-right: 8vw;
  margin-left: 55vw;
}

.trait-reverse {
  text-align: right;
  position: relative;
  margin-top: 80px;
}

.trait-reverse > .color-block {
  position: relative;
  display: inline-block;
  background: #1c2f3f;
  border-bottom-left-radius: 100px;
  width: 310px;
  height: 600px;
}

.trait-reverse > .color-block > .image {
  position: absolute;
  top: 50%;
  right: 5vw;
  transform: translateY(-50%);
  width: 40vw;
}

.trait-reverse > .text-block {
  text-align: left;
  position: absolute;
  top: 50%;
  left: 5vw;
  transform: translateY(-50%);
  margin-right: 55vw;
  margin-left: 8vw;
}

.text-block > .title {
  font-size: 30px;
  margin-bottom: 0px;
}

.text-block > .description {
  font-size: 15px;
  margin-top: 10px;
}

.text-block > .button {
  display: inline-block;
  margin-top: 60px;
  padding: 20px 80px 18px;
  border-radius: 100px;
  background-color: #1989ff;
  font-weight: 700;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  border: 2px white solid;
}

.text-block > .button:hover {
  background-color: transparent;
  color: #1989ff;
  border: 2px #1989ff solid;
}

@media (max-width: 905px) {
  .text-block > .button {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 30px 15px;
    border-radius: 100px;
    background-color: #1989ff;
    font-weight: 700;
    color: white;
    text-decoration: none;
  }
  .trait .imageFixed {
    display: block;
  }
  .trait-reverse .imageFixed {
    display: block;
  }
  .trait > .text-block {
    position: static;
    margin: 50px 40px 30px 40px;
    transform: translateY(0%);
  }

  .trait-reverse > .text-block {
    position: static;
    margin: 50px 20px 30px 20px;
    transform: translateY(0%);
  }

  .trait-reverse > .color-block > .image {
    display: none;
  }

  .trait > .color-block > .image {
    display: none;
  }
}
@media (max-width: 500px) {
  .trait > .color-block {
    width: 200px;
  }
  .trait > .color-block > .image {
    width: 80vw;
  }
  .trait > .text-block {
    position: static;
    bottom: 0;
  }
  .trait-reverse > .text-block {
    position: static;
    bottom: 0;
  }
  .trait-reverse > .color-block {
    width: 200px;
    height: 450px;
  }
  .trait-reverse > .color-block > .image {
    width: 80vw;
  }
  .text-block > .button {
    margin-top: 0px;
  }
}

.bottom-action {
  width: 100%;
  padding: 40px;
  text-align: center;
  margin-bottom: 100px;
}

.bottom-action > .title {
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 30px;
  font-weight: bold;
}

.bottom-action > .description {
  margin-top: 20px;
  color: #1989ff;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 60px;
  margin-left: 80px;
  margin-right: 80px;
}

.bottom-action > .description > .inner {
  display: inline-block;
  max-width: 600px;
}

.bottom-action > .button {
  padding: 20px 80px 18px;
  border-radius: 100px;
  background-color: #1989ff;
  color: white;
  font-weight: 700;
  text-decoration: none;
  border: 2px solid transparent;
  transition: 0.5s;
}

.bottom-action > .button:hover {
  background-color: transparent;
  color: #1989ff;
  border: 2px solid #1989ff;
}

@media (max-width: 500px) {
  .bottom-action > .title {
    margin-left: 0;
    margin-right: 0;
  }
  .bottom-action > .description {
    margin-top: 0;
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }
  .bottom-action {
    padding: 20px;
  }
}

.developer {
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
}

.developer > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.developer > .text-block {
  width: 100%;
}

.developer > .text-block > .name {
  font-weight: bold;
  padding-bottom: 10px;
  color: #fff;
  font-size: 18px;
}
.developer > .text-block > .role {
  color: #ffae65;
  font-size: 15px;
}

.developer > .text-block > .description {
  font-size: 15px;
  line-height: 18px;
}
/*Home*/

/*Theme Block*/
.theme-block {
  position: relative;
  background: #1c2f3f;
  border-bottom-right-radius: 100px;
  width: 63vw;
  padding: 15vw 0vmin 25vmin 10vw;
}

.theme-block > .decorate-ball {
  position: absolute;
  margin-top: 300px;
  right: -20px;
  background: #1989ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.theme-block > .paragraph {
  color: white;
  font-size: 30px;
  margin-bottom: 0px;
}

.theme-block > .title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: white;
  font-size: 50px;
  line-height: 55px;
}

.theme-block > .image {
  width: 50vw;
  position: absolute;
  top: 50%;
  left: 44vw;
  transform: translateY(-50%);
}

.theme-block > .button-reservation {
  display: inline-block;
  margin-top: 50px;
  text-decoration: none;
  padding: 20px 80px 18px;
  border-radius: 100px;
  background-color: #1989ff;
  color: white;
  border: 2px transparent solid;
  transition: 0.5s;
  font-size: 20px;
}

.theme-block > .button-reservation:hover {
  background-color: transparent;
  color: #1989ff;
  border: 2px #1989ff solid;
}

@media (max-width: 905px) {
  .theme-block > .image {
    display: none;
    width: 80vw;
  }

  .theme-block > .button-reservation {
    margin-top: 50px;
    text-decoration: none;
    padding: 20px 30px 18px;
    border-radius: 100px;
    background-color: #1989ff;
    color: white;
    font-size: 18px;
  }

  .theme-block {
    width: 70vw;
  }
}

@media (min-width: 1010px) {
  .theme-block > .decorate-ball {
    display: none;
  }
}

@media (max-width: 500px) {
  .theme-block {
    padding: 55vw 0 55vw 5vw;
  }
  .theme-block > .paragraph {
    font-size: 5vw;
  }

  .theme-block > .title {
    margin-top: 10px;
    font-size: 9vw;
    line-height: 10vw;
    font-weight: bold;
  }
  .theme-block > .button-reservation {
    padding: 10px 30px 9px;
  }
}

/*Sign Block*/
.sign-block {
  text-align: center;
  margin: 150px 120px 150px 120px;
}

@media (max-width: 900px) {
  .sign-block {
    margin: 150px 40px 150px 40px;
  }
}

.sign-block > .title {
  font-weight: bold;
  font-size: 35px;
}

.sign-block > .subtitle {
  margin-top: 50px;
  color: #1989ff;
  font-size: 15px;
}

/*Faith Block*/
.faith-block {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
}

.faith-block > .title {
  display: inline;
  margin-left: 120px;
  font-size: 30px;
}

.faith-block > .board {
  display: block;
  position: static;
  right: 0px;
  float: right;
  background: #1c2f3f;
  border-bottom-left-radius: 100px;
  width: 60vw;
  padding: 5vw 0vw 5vw 5vw;
}

.grid {
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.faith-block > .decorate-ball {
  position: absolute;
  margin-top: 60px;
  right: 58vw;
  background: #1989ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

/*faith component*/
.faith {
  margin: 10px;
  color: white;
  width: 23vw;
  display: inline-block;
}

.faith > .title {
  margin: 0px;
  display: block;
  font-size: 40px;
}

.faith > .description {
  margin: 1vw 0 0 0;
  display: inline-block;
  font-size: 15px;
}
/*faith component*/

@media (max-width: 905px) {
  .faith-block > .decorate-ball {
    right: 77vw;
  }

  .faith > .title {
    margin: 0px;
    display: block;
    font-size: 40px;
  }

  .faith-block > .title {
    display: block;
    width: 80vw;
    float: right;
  }
  .faith-block > .board {
    width: 80vw;
  }
  .faith {
    width: 30vw;
  }
}

@media (max-width: 700px) {
  .grid {
    display: inline-block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 0px;
    grid-column-gap: 0px;
  }
  .faith-block > .board {
    width: 80vw;

    padding-bottom: 20vw;
  }
  .faith > .title {
    font-size: 30px;
    line-height: 40px;
  }

  .faith {
    width: 60vw;
  }
  .faith-block > .decorate-ball {
    right: 75vw;
    top: 400px;
  }
  .faith-block > .title {
    width: 80vw;
  }
}

@media (max-width: 500px) {
  .faith-block > .title {
    width: 80vw;
    float: right;
  }
}

/*about-us*/
.about-block {
  margin-top: 12vw;
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
}

.about-block > .title {
  position: absolute;
  display: inline;
  left: 60px;
  font-size: 30px;
  font-weight: bold;
}

.about-block > .board {
  display: block;
  position: static;
  float: right;
  background: #1c2f3f;
  color: white;
  line-height: 30px;
  font-size: 16px;
  border-bottom-left-radius: 100px;
  width: 60vw;
  padding: 60px;
}

.about-block > .decorate-ball {
  position: absolute;
  right: 58vw;
  background: #1989ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@media (min-width: 906px) {
  .about-block > .decorate-ball {
    top: 60px;
  }
}

@media (max-width: 905px) {
  .about-block > .title {
    position: static;
    display: block;
    float: right;
    width: 80vw;
  }
  .about-block > .board {
    width: 80vw;
  }
  .about-block > .decorate-ball {
    right: 77vw;
    bottom: 80px;
  }
}

@media (max-width: 700px) {
}

@media (max-width: 500px) {
  .about-block > .title {
    width: 90vw;
    float: right;
  }

  .about-block > .board {
    width: 90vw;
    padding: 40px 10px 150px 60px;
  }

  .about-block > .decorate-ball {
    right: 82vw;
  }
}

.developer-block {
  position: relative;
  margin-top: 12vw;
  margin-bottom: 6vw;
  display: block;
  width: 100%;
  height: auto;
  top: 0;
}

.developer-block > .title {
  position: absolute;
  display: inline;
  left: 70vw;
  text-align: end;
  font-size: 30px;
  font-weight: bold;
}

.developer-block > .board {
  display: inline-block;
  position: static;
  background: #1c2f3f;
  color: white;
  line-height: 30px;
  font-size: 18px;
  border-bottom-right-radius: 100px;
  width: 65vw;
  padding: 150px 60px 150px 60px;
}

.developer-block > .board > .grid {
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.developer-block > .decorate-ball {
  position: absolute;
  left: 63vw;
  background: #1989ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@media (min-width: 991px) {
  .developer-block > .decorate-ball {
    top: 60px;
  }
}

@media (max-width: 990px) {
  .developer-block > .title {
    display: block;
    position: static;
    width: 80vw;
    float: left;
  }
  .developer-block > .board {
    width: 80vw;
    padding: 30px 30px 30px 20px;
  }
  .developer-block > .decorate-ball {
    left: 77vw;
    bottom: 80px;
  }
}

@media (max-width: 800px) {
  .developer-block > .board {
    width: 90vw;
  }
  .developer-block > .decorate-ball {
    left: 86vw;
    bottom: 80px;
  }
  .developer-block > .title {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .developer-block {
    margin-bottom: 120px;
  }
  .developer-block > .board > .grid {
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
  }
  .developer-block > .title {
    width: 90vw;
  }
}

/*footer*/
.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 50px 0 50px 0;
  background-color: #1c2f3f;
}

.footer.not-found {
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  width: 100%;
  padding: 50px 0 50px 0;
  background-color: #1c2f3f;
}

@media (max-width: 500px) {
  .footer {
    display: inline-block;
  }
}

.footer > .copyright-text {
  margin-left: 100px;
  color: #ffae65;
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

@media (max-width: 500px) {
  .footer > .copyright-text {
    margin-left: 40px;
  }
}

.footer > .community-list {
  display: inline;
  float: right;
  margin-right: 100px;
}

.footer > .community-list img {
  filter: brightness(1);
  transition: 0.3s;
}

.footer > .community-list img:hover {
  filter: brightness(2);
}

@media (max-width: 500px) {
  .footer > .community-list {
    display: block;
    float: none;
    margin-left: 40px;
    margin-top: 20px;
  }
}

.footer > .community-list > a {
  display: inline;
  margin-right: 15px;
}

/*about-us*/
.partnership-block {
  margin-top: 50px;
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  margin-bottom: 12vw;
}

.partnership-block > .title {
  position: absolute;
  display: inline;
  left: 60px;
  font-size: 30px;
  font-weight: bold;
}

.partnership-block > .board {
  display: block;
  position: static;
  float: right;
  background: #1c2f3f;
  color: white;
  line-height: 30px;
  font-size: 16px;
  border-bottom-left-radius: 100px;
  width: 60vw;
  padding: 60px;
}

.partnership-block > .decorate-ball {
  position: absolute;
  right: 58vw;
  background: #1989ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@media (min-width: 906px) {
  .partnership-block > .decorate-ball {
    top: 60px;
  }
}

@media (max-width: 905px) {
  .partnership-block > .title {
    position: static;
    display: block;
    float: right;
    width: 80vw;
  }
  .partnership-block > .board {
    width: 80vw;
  }
  .partnership-block > .decorate-ball {
    right: 77vw;
    bottom: 80px;
  }
}

@media (max-width: 500px) {
  .partnership-block > .title {
    width: 90vw;
    float: right;
  }
  .partnership-block > .board {
    width: 90vw;
    padding: 40px 10px 150px 60px;
  }

  .partnership-block > .decorate-ball {
    right: 82vw;
  }
}

.partner {
  text-align: center;
}

.partner > img {
  width: 200px;
  height: 200px;
}

.partner > .title {
  margin-top: 20px;
  font-size: 30px;
}

.partner > .description {
  margin-top: 10px;
  font-size: 16px;
}

.float-bubble{
  position: fixed;
  right:20px;
  bottom:100px;
  background-color: white;
  padding: 50px 25px 25px 25px;
  border-radius: 20px;
  box-shadow: 0 0 4px 0.5px gray;
  text-align: center;
  transition: 0.5s;
}

.float-bubble .close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px;
  color: gray;
  cursor: pointer;
}

.float-bubble > h1{
  font-size: 20px;
  text-align: center;
}

.float-bubble > p{
  font-size: 16px;
  text-align: center;
}

.float-bubble > .button {
  display: inline-block;
  padding: 8px 40px ;
  border-radius: 100px;
  background-color: #1989ff;
  font-weight: 700;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  border: 2px #1989ff solid;
}

.float-bubble > .button:hover {
  background-color: white;
  color: #1989ff;
}
